var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Add Service" } }),
      _c("gov-back-link", { attrs: { to: { name: "services-index" } } }, [
        _vm._v("Back to services")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Services")
                  ]),
                  !_vm.auth.isSuperAdmin
                    ? [
                        _c(
                          "gov-body",
                          { staticClass: "govuk-!-font-weight-bold" },
                          [
                            _vm._v(
                              "\n            Please review the process below on how to create a\n            " +
                                _vm._s(_vm.form.type) +
                                ".\n          "
                            )
                          ]
                        ),
                        _c("gov-list", { attrs: { bullet: "" } }, [
                          _c("li", [
                            _vm._v(
                              "To create a " +
                                _vm._s(_vm.form.type) +
                                ", fill in the form below."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "\n              The " +
                                _vm._s(_vm.form.type) +
                                " won't be made active until an admin has\n              reviewed it.\n            "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "\n              If there are any issues upon review, an admin will get directly\n              in touch with you.\n            "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "You can return to edit this " +
                                _vm._s(_vm.form.type) +
                                " at any time."
                            )
                          ]),
                          _c(
                            "li",
                            [
                              _vm._v(
                                "\n              If you would like your service to accept referrals through\n              " +
                                  _vm._s(_vm.appName) +
                                  ", please contact the team at\n              "
                              ),
                              _c(
                                "gov-link",
                                {
                                  attrs: { href: "mailto:" + _vm.contactEmail }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.contactEmail) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _vm.updateRequestCreated
                          ? _c(
                              "div",
                              [
                                _c(
                                  "gov-heading",
                                  { attrs: { size: "m", tag: "h3" } },
                                  [_vm._v("Create service request")]
                                ),
                                _c("gov-body", [
                                  _vm._v(_vm._s(_vm.updateRequestMessage))
                                ]),
                                _c(
                                  "gov-back-link",
                                  { attrs: { to: { name: "services-index" } } },
                                  [_vm._v("Back to services")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  !_vm.updateRequestCreated
                    ? [
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Add service")
                        ]),
                        _vm.form.$errors.any()
                          ? _c(
                              "gov-error-summary",
                              { attrs: { title: "Check for errors" } },
                              [
                                _c(
                                  "gov-list",
                                  _vm._l(_vm.form.$errors.all(), function(
                                    error,
                                    field
                                  ) {
                                    return _c("li", {
                                      key: field,
                                      domProps: {
                                        textContent: _vm._s(error[0])
                                      }
                                    })
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "gov-tabs",
                          {
                            attrs: { tabs: _vm.allowedTabs, "no-router": "" },
                            on: { "tab-changed": _vm.onTabChange }
                          },
                          [
                            _c(
                              "details-tab",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isTabActive("details"),
                                    expression: "isTabActive('details')"
                                  }
                                ],
                                attrs: {
                                  errors: _vm.form.$errors,
                                  "is-new": true,
                                  name: _vm.form.name,
                                  slug: _vm.form.slug,
                                  type: _vm.form.type,
                                  organisation_id: _vm.form.organisation_id,
                                  url: _vm.form.url,
                                  status: _vm.form.status,
                                  score: _vm.form.score,
                                  ends_at: _vm.form.ends_at,
                                  gallery_items: _vm.form.gallery_items,
                                  tags: _vm.form.tags,
                                  logo_file_id: _vm.form.logo_file_id
                                },
                                on: {
                                  "update:name": function($event) {
                                    return _vm.$set(_vm.form, "name", $event)
                                  },
                                  "update:slug": function($event) {
                                    return _vm.$set(_vm.form, "slug", $event)
                                  },
                                  "update:type": function($event) {
                                    return _vm.$set(_vm.form, "type", $event)
                                  },
                                  "update:organisation_id": function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "organisation_id",
                                      $event
                                    )
                                  },
                                  "update:url": function($event) {
                                    return _vm.$set(_vm.form, "url", $event)
                                  },
                                  "update:status": function($event) {
                                    return _vm.$set(_vm.form, "status", $event)
                                  },
                                  "update:score": function($event) {
                                    return _vm.$set(_vm.form, "score", $event)
                                  },
                                  "update:ends_at": function($event) {
                                    return _vm.$set(_vm.form, "ends_at", $event)
                                  },
                                  "update:gallery_items": function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "gallery_items",
                                      $event
                                    )
                                  },
                                  "update:tags": function($event) {
                                    return _vm.$set(_vm.form, "tags", $event)
                                  },
                                  clear: function($event) {
                                    _vm.form.$errors.clear($event)
                                    _vm.errors = {}
                                  },
                                  "update:logo_file_id": function($event) {
                                    _vm.form.logo_file_id = $event
                                  },
                                  "image-changed": function($event) {
                                    _vm.imageChanged = $event
                                  }
                                }
                              },
                              [
                                _c(
                                  "gov-button",
                                  {
                                    attrs: { start: "" },
                                    on: { click: _vm.onNext }
                                  },
                                  [_vm._v("Next")]
                                )
                              ],
                              1
                            ),
                            _vm.isTabActive("additional-info")
                              ? _c(
                                  "additional-info-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      type: _vm.form.type,
                                      wait_time: _vm.form.wait_time,
                                      is_free: _vm.form.is_free,
                                      fees_text: _vm.form.fees_text,
                                      fees_url: _vm.form.fees_url,
                                      testimonial: _vm.form.testimonial,
                                      video_embed: _vm.form.video_embed,
                                      contact_name: _vm.form.contact_name,
                                      contact_phone: _vm.form.contact_phone,
                                      contact_email: _vm.form.contact_email,
                                      social_medias: _vm.form.social_medias,
                                      cqc_location_id: _vm.form.cqc_location_id
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:wait_time": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "wait_time",
                                          $event
                                        )
                                      },
                                      "update:is_free": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "is_free",
                                          $event
                                        )
                                      },
                                      "update:fees_text": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "fees_text",
                                          $event
                                        )
                                      },
                                      "update:fees_url": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "fees_url",
                                          $event
                                        )
                                      },
                                      "update:testimonial": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "testimonial",
                                          $event
                                        )
                                      },
                                      "update:video_embed": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "video_embed",
                                          $event
                                        )
                                      },
                                      "update:contact_name": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "contact_name",
                                          $event
                                        )
                                      },
                                      "update:contact_phone": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "contact_phone",
                                          $event
                                        )
                                      },
                                      "update:contact_email": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "contact_email",
                                          $event
                                        )
                                      },
                                      "update:social_medias": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "social_medias",
                                          $event
                                        )
                                      },
                                      "update:cqc_location_id": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "cqc_location_id",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "gov-button",
                                      {
                                        attrs: { start: "" },
                                        on: { click: _vm.onNext }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isTabActive("useful-info")
                              ? _c(
                                  "useful-info-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      type: _vm.form.type,
                                      useful_infos: _vm.form.useful_infos
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:useful_infos": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "useful_infos",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "gov-button",
                                      {
                                        attrs: { start: "" },
                                        on: { click: _vm.onNext }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isTabActive("eligibility")
                              ? _c(
                                  "eligibility-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      type: _vm.form.type,
                                      serviceEligibilityTypes:
                                        _vm.form.eligibility_types
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:serviceEligibilityTypes": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "eligibility_types",
                                          $event
                                        )
                                      },
                                      "update:service-eligibility-types": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "eligibility_types",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "gov-button",
                                      {
                                        attrs: { start: "" },
                                        on: { click: _vm.onNext }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isTabActive("taxonomies")
                              ? _c(
                                  "taxonomies-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      "is-global-admin": _vm.auth.isGlobalAdmin,
                                      type: _vm.form.type,
                                      category_taxonomies:
                                        _vm.form.category_taxonomies
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:category_taxonomies": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "category_taxonomies",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "gov-button",
                                      {
                                        attrs: { start: "" },
                                        on: { click: _vm.onNext }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isTabActive("description")
                              ? _c(
                                  "description-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      type: _vm.form.type,
                                      intro: _vm.form.intro,
                                      offerings: _vm.form.offerings,
                                      description: _vm.form.description
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:intro": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "intro",
                                          $event
                                        )
                                      },
                                      "update:offerings": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "offerings",
                                          $event
                                        )
                                      },
                                      "update:description": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "description",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "gov-button",
                                      {
                                        attrs: { start: "" },
                                        on: { click: _vm.onNext }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isTabActive("referral")
                              ? _c(
                                  "referral-tab",
                                  {
                                    attrs: {
                                      errors: _vm.form.$errors,
                                      "is-global-admin": _vm.auth.isGlobalAdmin,
                                      "is-super-admin": _vm.auth.isSuperAdmin,
                                      type: _vm.form.type,
                                      show_referral_disclaimer:
                                        _vm.form.show_referral_disclaimer,
                                      referral_method: _vm.form.referral_method,
                                      referral_button_text:
                                        _vm.form.referral_button_text,
                                      referral_email: _vm.form.referral_email,
                                      referral_url: _vm.form.referral_url
                                    },
                                    on: {
                                      clear: function($event) {
                                        _vm.form.$errors.clear($event)
                                        _vm.errors = {}
                                      },
                                      "update:show_referral_disclaimer": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "show_referral_disclaimer",
                                          $event
                                        )
                                      },
                                      "update:referral_method": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "referral_method",
                                          $event
                                        )
                                      },
                                      "update:referral_button_text": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "referral_button_text",
                                          $event
                                        )
                                      },
                                      "update:referral_email": function(
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.form,
                                          "referral_email",
                                          $event
                                        )
                                      },
                                      "update:referral_url": function($event) {
                                        return _vm.$set(
                                          _vm.form,
                                          "referral_url",
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm.form.$submitting
                                      ? _c(
                                          "gov-button",
                                          {
                                            attrs: {
                                              disabled: "",
                                              type: "submit"
                                            }
                                          },
                                          [_vm._v("Creating...")]
                                        )
                                      : _c(
                                          "gov-button",
                                          {
                                            attrs: {
                                              disabled: _vm.imageChanged,
                                              type: "submit"
                                            },
                                            on: { click: _vm.onSubmit }
                                          },
                                          [_vm._v("Create")]
                                        ),
                                    _vm.form.$errors.any()
                                      ? _c("ck-submit-error")
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }